@import "./toaster-theme.scss";
/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: rgb(247, 246, 249)  //#009688;
}

$primay-color: #00796b;

.dark-primary-color {
  background: #00796b;
}
.default-primary-color {
  background: #009688;
}
.light-primary-color {
  background: #b2dfdb;
}
.text-primary-color {
  color: #ffffff;
}
.accent-color {
  background: #607d8b;
}
.primary-text-color {
  color: #212121;
}
.secondary-text-color {
  color: #757575;
}
.divider-color {
  border-color: #bdbdbd;
}

.primary {
  color: #fff;
  background-color: $primay-color;
}

.text-align-center {
  text-align: center;
}

.color-white {
  color: #fff;
}
